<div class="card h-100 blankpage-height">
    <div class="card-body">
        <h5>Search</h5>
        <div class="row mb-2 g-2 align-items-center">
            <div class="col-md-3" *ngIf="isSystemAgent">
                <label>Broker</label>
                <kendo-combobox [data]="AgentData" [valuePrimitive]="true" [(ngModel)]="searchParameters.agentId" #agentId [openOnFocus]="agentId"
                    [textField]="'tradingName'" [valueField]="'agentId'" class="w-100" placeholder="Select Broker"  [filterable]="true"   [kendoDropDownFilter]="filterSettings" >
                </kendo-combobox>
            </div>
            <div class="col-md">
                <label>Product</label>
                <kendo-combobox [data]="ProductData" [valuePrimitive]="true" [(ngModel)]="searchParameters.productId" #productId [openOnFocus]="productId"
                    [textField]="'text'" [valueField]="'value'" class="w-100" placeholder="Select Product" (valueChange)="reLoadTypeData($event)">
                </kendo-combobox>
            </div>
            <div class="col-md">
                <label>Type</label>
                <kendo-combobox [data]="StatusData" [valuePrimitive]="true"  #policystatusId [openOnFocus]="policystatusId"
                    [(ngModel)]="searchParameters.policystatusId" [textField]="'text'" [valueField]="'value'"
                    class="w-100" placeholder="Select Type">
                </kendo-combobox>

            </div>
            <div class="col-md">
                <label>Proposer</label>
                <input type="text" class="form-control" [(ngModel)]="searchParameters.proposer"
                    placeholder="Leave blank to show all">
            </div>
            <div class="col-md">
                <label>Reference Number</label>
                <input type="text" class="form-control" [(ngModel)]="searchParameters.reference"
                    placeholder="Leave blank to show all">
            </div>
            <div class="col-md-auto col-auto if-no-label">
                <button type="button" class="btn btn-primary" (click)="submit()">
                    Search
                </button>
            </div>
            <div class="col-md-auto col-auto if-no-label">
                <button type="button" class="btn btn-secondary" (click)="clear()">
                    Clear
                </button>
            </div>
        </div>
        <div class="col-md-12">
            <span> <label>Total Records returned: </label><span class="font-weight-semibold">{{this.count}}</span> (Last
                30 days only)</span>
        </div>
        <div class="row">
            <div class="col-md-12">
                <kendo-grid [data]="gridData|async" [sortable]="true" (dataStateChange)="dataStateChangePolicy($event)"
                    [pageSize]="state.take" [skip]="state.skip" [sort]="state.sort" [pageable]="true"
                    autoFitColumns="true" [sortable]="true" [resizable]="true">
                    <!-- <ng-template kendoGridToolbarTemplate>
                        <div class="row g-2 w-100">
                            <div class="col-md m-top-10">
                                
                            </div>
                            <div class="col-md-auto col-auto m-top-10">
                              
                            </div>
                            <div class="col-md-auto col-auto m-top-10">
                                
                            </div>
                        </div>
                    </ng-template> -->
                    <kendo-grid-column field="referenceNo" title="Reference No" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <a (click)="getQuote(dataItem)">{{dataItem.referenceNo}} </a>
                            <span *ngIf="dataItem.policySubStatus == 'NTU'" class="custom-alert">
                                NTU
                            </span>
                            <span *ngIf="dataItem.policySubStatus == 'Cancel'" class="custom-alert">
                                Canx
                            </span>
                            <span *ngIf="((dataItem.policySubStatusId == 1 ||dataItem.policySubStatusId==17) && dataItem.policyEndDate > latestDate && this.selectedStatusId !=4)"
                                class="custom-alert-green">
                                Live
                            </span>
                            <span *ngIf="((dataItem.policySubStatusId == 1 || dataItem.policySubStatusId==17) && dataItem.policyEndDate > latestDate && this.selectedStatusId == 4)"
                                class="custom-alert-old">
                                Old
                            </span>
                            <!-- <span
                                *ngIf="dataItem.policySubStatus != 'Active' && dataItem.policySubStatus != 'NTU' && dataItem.policyEndDate < latestDate && dataItem.policyState == 3"
                                class="custom-alert-old">
                                Old
                            </span> -->
                            
                            <span
                                *ngIf="dataItem.policySubStatus == 'Active' && dataItem.policyEndDate < latestDate && this.selectedStatusId != 3"
                                class="custom-alert-lapsed">
                                Lapsed
                            </span>
                            <span
                                *ngIf="(dataItem.policySubStatusId == 1 ||dataItem.policySubStatusId==17) && dataItem.policyEndDate < latestDate && (this.selectedStatusId == 3 || this.selectedStatusId == 1 || this.selectedStatusId == 4 )"
                                class="custom-alert-old">
                                Old
                            </span>
                           
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="businessName" title="Proposer" [headerStyle]="{ width: '150px' }"
                        [style]="{ width: '150px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="text-truncate">
                                {{dataItem?.businessName}}
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="tradingName" title="Broker" [headerStyle]="{ width: '150px' }"
                        [style]="{ width: '150px' }">

                    </kendo-grid-column>

                    <kendo-grid-column field="createdDate" title="Date Saved" [headerStyle]="{ width: '150px' }"
                        *ngIf="this.selectedStatusId !== 8  && this.selectedStatusId !== 3 && this.selectedStatusId !== 5"
                        [style]="{ width: '150px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.createdDate | date: "dd/MM/yyyy" }}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="policyEndDate" title="Ren Date" [headerStyle]="{ width: '150px' }"
                        *ngIf="selectedStatusId==8" [style]="{ width: '150px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.policyEndDate | date: "dd/MM/yyyy" }}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="inceptionDate" title="Inception" [headerStyle]="{ width: '150px' }"
                        *ngIf="selectedStatusId==3" [style]="{ width: '150px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.inceptionDate | date: "dd/MM/yyyy" }}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="policyEndDate" title="Lapsed Date" [headerStyle]="{ width: '150px' }"
                        *ngIf="selectedStatusId==5" [style]="{ width: '150px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.policyEndDate | date: "dd/MM/yyyy" }}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="Premium" title="Premium" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <!-- <div class="text-truncate" *ngIf="canShowPremium(dataItem)"> -->
                            <div class="text-truncate"
                                *ngIf="dataItem?.premium  && dataItem.policySubStatusId!=14 && dataItem.policySubStatusId!=13 && dataItem.policySubStatusId!=8  && dataItem.policySubStatusId!=5">
                                € {{dataItem?.premium | currency:' ':'symbol':'0.2-2' }}
                            </div>

                            <div class="text-truncate"
                                *ngIf="!dataItem?.premium  && dataItem.policySubStatusId!=8 && dataItem.policySubStatusId!=14 && dataItem.policySubStatusId!=13 && dataItem.policySubStatusId!=5">
                                <a (click)="getQuote(dataItem)">Click for terms</a>
                            </div>
                            <div class="text-truncate"
                                *ngIf="dataItem.policySubStatusId==14 || dataItem.policySubStatusId===13 || dataItem.policySubStatusId===8 ">
                                Refer
                            </div>
                            <div class="text-truncate" *ngIf="dataItem.policySubStatusId==5">
                                Decline
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- <kendo-grid-column field="policySubStatus" title="Status" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                    </kendo-grid-column> -->
                </kendo-grid>
            </div>
        </div>
    </div>
</div>