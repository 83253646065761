import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteDetailsDialogComponent } from '@app/quote-details-dialog/quote-details-dialog.component';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { State } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { ToasterService } from '@shared/utility/toaster.service';
import { Subscription } from 'rxjs';
import { GridDataPolicyService } from '@app/services/gridData.policy.service';
import { ListTable } from '@shared/models';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { InitPolicyStatus, PolicyStatus, PolicySubStatus, ProductType } from '@shared/utility/enum';
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { DatePipe } from '@angular/common';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
@Component({
  selector: 'app-search-policy',
  templateUrl: './search-policy.component.html',
  styleUrl: './search-policy.component.css'
})

export class SearchPolicyComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  agentId = 0;
  selectedStatusId = 1;
  public ProductData: Array<ListTable>;
  public ProductDataClone: Array<ListTable>;

  public AgentData: Array<ListTable>;
  public AgentDataClone: Array<ListTable>;

  public PolicyData: Array<ListTable>;
  public lstdataPolicyType: Array<ListTable>;

  public StatusData: Array<ListTable>;
  public StatusDataClone: Array<ListTable>;
  public PolicyType: Array<ListTable>;
  public lstdataPolicystatus: Array<ListTable>;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  index = 0;
  count: any;
  policyApiBasePath = 'Insurance/Policy/SearchPolicy';
  public totalCount = 0;
  searchText: any;
  public state: State = {
    skip: 0,
    take: 10,
    filter: { filters: [], logic: "or" },
  };
  gridData: any;
  gridDataView: any;
  isSystemAgent: boolean = false;

  public searchParameters = {
    agentId: null,
    policyTypeId: null,
    policyNo: null,
    proposer: null,
    reference: null,
    policystatusId: null,
    productId: null
  };
  currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  latestDate: any;
  constructor(private fb: FormBuilder, private insuranceService: InsuranceService, private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService,
    private confirMationDialogService: ConfirmationDialogService,
    private router: Router,
    private gridDataService: GridDataPolicyService,
    private oidcSecurityService: OidcSecurityService,
    public datepipe: DatePipe
  ) {
    this.latestDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd h:mm:ss');
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit() {
    if (this.oidcSecurityService.isAuthenticated$) {
      const auth = JSON.parse(window.sessionStorage.getItem('authentication'));
      this.isSystemAgent = auth.isSystemAgent;
      //this.searchParameters.productId = ProductType.ELPL;
      this.activatedRoute.queryParams.subscribe(params => {
        const decryptedParams = this.insuranceService.decryptAndParsePolicyStatus(params.data);
        if (decryptedParams) {
          this.searchParameters.policystatusId = +decryptedParams.policystatusId;
        }
      });
    }
    this.loadDropdown();
  }


  getQuote(dataItem: any) {
    this.reDirectDashboard(dataItem);
  }
  loadDropdown() {
    const claimMappings = {
      'CANVIEWBBGUHOUSE': 1,
      'CANVIEWCOMCOMBINED': 2,
      'CANVIEWCONTRAALLRISK': 4,
      'CANVIEWELPL': 5,
      'CANVIEWOFFICE': 6,
      'CANVIEWPERSNLACCIDENT': 7,
      'CANVIEWPROFINDEM': 8,
      'CANVIEWPROPOWNERS': 9,
      'CANVIEWPUBHOUSEREST': 10,
      'CANVIEWSELFBUILD': 11,
      'CANVIEWSHOPRETAIL': 12,
      'CANVIEWREALESTATE': 13,
    };

    this.subscription.add(
      this.insuranceService.geProducts().subscribe((data: Array<ListTable>): void => {
        const auth = JSON.parse(window.sessionStorage.getItem('authentication'));
        if (auth && auth.claims && auth.claims.length > 0) {
          data = data.filter(product =>
            auth.claims.some(claim => claimMappings[claim] === product.value)
          );
        }
        this.ProductData = data;
        this.ProductDataClone = Object.assign([], data);
      })
    );
    this.subscription.add(
      this.insuranceService.getAgent().subscribe((data: Array<ListTable>): void => {
        this.AgentData = data;
        this.AgentDataClone = Object.assign([], data);

      }));
    this.subscription.add(
      this.insuranceService.getPolicyType().subscribe((data: Array<ListTable>): void => {
        this.PolicyData = data;
        this.lstdataPolicyType = Object.assign([], data);
      }));
    this.subscription.add(
      this.insuranceService.getPolicyStatus().subscribe((data: Array<ListTable>): void => {
        // if (!this.isSystemAgent){
        //   data = data.filter(t => t.value != 4 && t.value != 6 && t.value!=7);
        // }

        this.StatusData = data;
        this.StatusData.map((obj, index) => {
          if (obj.text === 'Quote') obj.code = '1';
          if (obj.text === 'Policy') obj.code = '2';
          if (obj.text === 'Renewals') obj.code = '3';
          if (obj.text === 'MTA Quote') obj.code = '4';
          if (obj.text === 'Lapsed') obj.code = '5';
          if (obj.text === 'Cancelled') obj.code = '6';
          if (obj.text === 'Declined') obj.code = '7';
        })
        this.StatusData.sort((a, b) => a.code > b.code ? 1 : -1);
        this.StatusDataClone = Object.assign([], data);
        this.lstdataPolicystatus = Object.assign([], data);
        if (!this.isSystemAgent) {
          //this.StatusData = this.StatusData.filter(x => x.value != 6 && x.value != 7);
          this.StatusData = this.StatusData.filter(x => x.value != 6); // as pr day1  CR  mta shwig to Broker
        }
        this.PolicyType = Object.assign([], this.StatusData);
      }));
  }
  reLoadTypeData(productId) {
    if (!this.isSystemAgent) {
      if ((productId === 13 || productId === 9)) {
        this.StatusDataClone.map((obj, index) => {
          if (obj.text === 'Quote') obj.code = '1';
          if (obj.text === 'Policy') obj.code = '2';
          if (obj.text === 'Renewals') obj.code = '3';
          if (obj.text === 'MTA Quote') obj.code = '4';
          if (obj.text === 'Lapsed') obj.code = '5';
          if (obj.text === 'Cancelled') obj.code = '6';
          if (obj.text === 'Declined') obj.code = '7';
        })
        this.StatusData = this.StatusDataClone;
        this.StatusData = this.StatusData.filter(x => x.value != 6);
        this.StatusData.sort((a, b) => a.code > b.code ? 1 : -1);
      }
      else {
        if (productId != 13 && productId != 9 && this.searchParameters.policystatusId == 7) {
          this.searchParameters.policystatusId = 1;
        }
        //this.StatusData = this.StatusData.filter(x => x.value != 7);
      }
    }
    //   this.StatusData = null;
    //   if ((productId === 13 || productId === 9)) {
    //     this.subscription.add(
    //       this.insuranceService.getPolicyStatus().subscribe((data: Array<ListTable>): void => {
    //         if (!this.isSystemAgent)
    //           data = data.filter(t => t.value != 4 && t.value != 6);
    //         this.StatusData = data;
    //         this.StatusData.map((obj, index) => {
    //           if (obj.text === 'Quote') obj.code = '1';
    //           if (obj.text === 'Policy') obj.code = '2';
    //           if (obj.text === 'Renewals') obj.code = '3';
    //           if (obj.text === 'MTA Quote') obj.code = '4';
    //           if (obj.text === 'Lapsed') obj.code = '5';
    //           if (obj.text === 'Cancelled') obj.code = '6';
    //           if (obj.text === 'Declined') obj.code = '7';
    //         })
    //         this.StatusData.sort((a, b) => a.code > b.code ? 1 : -1)
    //         this.lstdataPolicystatus = Object.assign([], data);
    //       }));
    //   } else {
    //     this.StatusData = Object.assign([], this.PolicyType);
    //     this.searchParameters.policystatusId = null;
    //   }
    // }
  }
  loadData() {
    this.gridDataService.state = this.state;
    this.gridDataService.read(
      "",
      null,
      this.searchParameters
    );
    this.gridData = this.gridDataService;

    this.subscription.add(
      this.gridData.subscribe((data: any): void => {
        if (data != null && data != undefined) {
          this.count = data.total;
        }
      })
    );
  }




  submit() {
    if (!this.searchParameters.productId || this.searchParameters.productId === null) {
      this.toasterService.warning('Please select product');
      return;
    }

    if (!this.searchParameters.policystatusId || this.searchParameters.policystatusId === null) {
      this.toasterService.warning('Please select type');
      return;
    }
    this.state.take = 10;
    this.state.skip = 0;
    this.gridDataService.state = this.state;
    if (this.searchParameters.productId == ProductType.ELPL) {
      this.gridDataService.readCommman("ELPL", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Fcc) {
      this.gridDataService.readCommman("Fcc", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Off) {
      this.gridDataService.readCommman("Off", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Lei) {
      this.gridDataService.readCommman("Lei", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Shop) {
      this.gridDataService.readCommman("Shop", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Car) {
      this.gridDataService.readCommman("Car", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Sb) {
      this.gridDataService.readCommman("Sb", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Rei) {
      this.gridDataService.readCommman("Rei", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Pi) {
      this.gridDataService.readCommman("Pi", "", this.searchParameters);

    } else if (this.searchParameters.productId == ProductType.Bbg) {
      this.gridDataService.readCommman("Bbg", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Pa) {
      this.gridDataService.readCommman("Pa", "", this.searchParameters);

    } else if (this.searchParameters.productId == ProductType.Poi) {
      this.gridDataService.readCommman("Po", "", this.searchParameters);

    }
    if (!this.isSystemAgent && this.searchParameters.policystatusId == 7
      && this.searchParameters.productId !== ProductType.Poi
      && this.searchParameters.productId !== ProductType.Rei
    ) {
      return this.gridData = null;;
    }
    this.gridData = this.gridDataService;
    this.selectedStatusId = this.searchParameters.policystatusId;
    this.searchParameters.proposer = this.searchParameters.proposer;
    this.searchParameters.reference = this.searchParameters.reference;
  }
  clear() {
    this.searchParameters.agentId = null;
    this.searchParameters.productId = null;
    this.searchParameters.policystatusId = null;
    this.searchParameters.policyTypeId = null;
    this.searchParameters.reference = null;
    this.searchParameters.proposer = null;
    this.searchParameters.policyNo = null;
    this.state.take = 10;
    this.state.skip = 0;
    this.gridDataService.state = this.state;
    this.gridData = null;
    // this.gridDataService.read(
    //   "",
    //   "",
    //   this.searchParameters
    // );
  }

  canShowPremium(dataItem) {
    return +dataItem?.premium > 0 && dataItem.policySubStatusId !== 8 && dataItem.policySubStatusId !== 14 && dataItem.policySubStatusId !== 10 && dataItem.policySubStatusId != 5 && dataItem.policySubStatusId != 13;
  }

  public dataStateChangePolicy(state: DataStateChangeEvent): void {
    this.gridDataService.state = state;
    this.state = state;
    if (this.searchParameters.productId == ProductType.ELPL) {
      this.gridDataService.readCommman("ELPL", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Fcc) {
      this.gridDataService.readCommman("Fcc", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Off) {
      this.gridDataService.readCommman("Off", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Lei) {
      this.gridDataService.readCommman("Lei", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Shop) {
      this.gridDataService.readCommman("Shop", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Car) {
      this.gridDataService.readCommman("Car", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Sb) {
      this.gridDataService.readCommman("Sb", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Rei) {
      this.gridDataService.readCommman("Rei", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Pi) {
      this.gridDataService.readCommman("Pi", "", this.searchParameters);

    } else if (this.searchParameters.productId == ProductType.Bbg) {
      this.gridDataService.readCommman("Bbg", "", this.searchParameters);
    } else if (this.searchParameters.productId == ProductType.Pa) {
      this.gridDataService.readCommman("Pa", "", this.searchParameters);

    } else if (this.searchParameters.productId == ProductType.Poi) {
      this.gridDataService.readCommman("Po", "", this.searchParameters);

    }
  }

  reDirectDashboard(dataItem: any) {
    let encryptedqueryparams: { policyId: number; isRenewed: any };
    encryptedqueryparams = { policyId: dataItem.policyId, isRenewed: dataItem.isRenewal }
    if (dataItem.productId === ProductType.ELPL) {
      if (dataItem.isRenewal == true && dataItem.policySubStatusId != PolicySubStatus.RenewalProceed && dataItem.policySubStatusId != PolicySubStatus.RenewalReferred && dataItem.policySubStatusId != PolicySubStatus.Decline) {
        this.editQuote(dataItem);
      }
      else if (dataItem.policyState == InitPolicyStatus.Quote || dataItem.policySubStatusId == PolicySubStatus.RenewalProceed || dataItem.policySubStatusId == PolicySubStatus.RenewalReferred || dataItem.policySubStatusId == PolicySubStatus.MtaPending || dataItem.policySubStatusId == PolicySubStatus.MTAReferred || dataItem.policySubStatusId == PolicySubStatus.Decline) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product/quote-details"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
      else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product/policy-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }

    }
    else if (dataItem.productId === ProductType.Lei || dataItem.productId === ProductType.Fcc || dataItem.productId === ProductType.Shop || dataItem.productId === ProductType.Off) {
      if (dataItem.isRenewal == true && dataItem.policySubStatusId != PolicySubStatus.RenewalProceed && dataItem.policySubStatusId != PolicySubStatus.RenewalReferred && dataItem.policySubStatusId != PolicySubStatus.Decline) {
        this.editQuote(dataItem);
      }
      else if (dataItem.policyState == InitPolicyStatus.Quote || dataItem.policySubStatusId == PolicySubStatus.RenewalProceed || dataItem.policySubStatusId == PolicySubStatus.RenewalReferred || dataItem.policySubStatusId == PolicySubStatus.MtaPending || dataItem.policySubStatusId == PolicySubStatus.MTAReferred || dataItem.policySubStatusId == PolicySubStatus.Decline) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product/commquote-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
      else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product/ccpolicy-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
    }
    else if (dataItem.productId === ProductType.Car) {
      if (dataItem.isRenewal == true && dataItem.policySubStatusId != PolicySubStatus.RenewalProceed && dataItem.policySubStatusId != PolicySubStatus.RenewalReferred && dataItem.policySubStatusId != PolicySubStatus.Decline) {
        this.editQuote(dataItem);
      }
      else if (dataItem.policyState == InitPolicyStatus.Quote || dataItem.policySubStatusId == PolicySubStatus.RenewalProceed || dataItem.policySubStatusId == PolicySubStatus.RenewalReferred || dataItem.policySubStatusId == PolicySubStatus.MtaPending || dataItem.policySubStatusId == PolicySubStatus.MTAReferred || dataItem.policySubStatusId == PolicySubStatus.Decline) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product/car-quote-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
      else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product/car-policy-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
    }
    else if (dataItem.productId === ProductType.Sb) {
      if (dataItem.isRenewal == true && dataItem.policySubStatusId != PolicySubStatus.RenewalProceed && dataItem.policySubStatusId != PolicySubStatus.RenewalReferred && dataItem.policySubStatusId != PolicySubStatus.Decline) {
        this.editQuote(dataItem);
      }
      else if (dataItem.policyState == InitPolicyStatus.Quote || dataItem.policySubStatusId == PolicySubStatus.RenewalProceed || dataItem.policySubStatusId == PolicySubStatus.RenewalReferred || dataItem.policySubStatusId == PolicySubStatus.MtaPending || dataItem.policySubStatusId == PolicySubStatus.MTAReferred || dataItem.policySubStatusId == PolicySubStatus.Decline) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product/sbquote-details"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
      else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product/sb-policy-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
    }
    else if (dataItem.productId === ProductType.Rei) {
      if (dataItem.isRenewal == true && dataItem.policySubStatusId != PolicySubStatus.RenewalProceed && dataItem.policySubStatusId != PolicySubStatus.RenewalReferred && dataItem.policySubStatusId != PolicySubStatus.Decline) {
        this.editQuote(dataItem);
      }
      else if (dataItem.policyState == InitPolicyStatus.Quote || dataItem.policySubStatusId == PolicySubStatus.RenewalProceed || dataItem.policySubStatusId == PolicySubStatus.RenewalReferred || dataItem.policySubStatusId == PolicySubStatus.MtaPending || dataItem.policySubStatusId == PolicySubStatus.MTAReferred || dataItem.policySubStatusId == PolicySubStatus.Decline) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product/reiquote-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
      else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product/reipolicy-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
    }
    else if (dataItem.productId === ProductType.Pi) {
      if (dataItem.isRenewal == true && dataItem.policySubStatusId != PolicySubStatus.RenewalProceed && dataItem.policySubStatusId != PolicySubStatus.RenewalReferred && dataItem.policySubStatusId != PolicySubStatus.Decline) {
        this.editQuote(dataItem);
      }
      else if (dataItem.policyState == InitPolicyStatus.Quote || dataItem.policySubStatusId == PolicySubStatus.RenewalProceed || dataItem.policySubStatusId == PolicySubStatus.RenewalReferred || dataItem.policySubStatusId == PolicySubStatus.MtaPending || dataItem.policySubStatusId == PolicySubStatus.MTAReferred || dataItem.policySubStatusId == PolicySubStatus.Decline) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product-tm/pi-quote-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
      else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product-tm/pi-policy-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
    }
    else if (dataItem.productId === ProductType.Pa) {
      if (dataItem.isRenewal == true && dataItem.policySubStatusId != PolicySubStatus.RenewalProceed && dataItem.policySubStatusId != PolicySubStatus.RenewalReferred && dataItem.policySubStatusId != PolicySubStatus.Decline) {
        this.editQuote(dataItem);
      }
      else if (dataItem.policyState == InitPolicyStatus.Quote || dataItem.policySubStatusId == PolicySubStatus.RenewalProceed || dataItem.policySubStatusId == PolicySubStatus.RenewalReferred || dataItem.policySubStatusId == PolicySubStatus.MtaPending || dataItem.policySubStatusId == PolicySubStatus.MTAReferred || dataItem.policySubStatusId == PolicySubStatus.Decline) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product-tm/pa-quote-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
      else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product-tm/pa-policy-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
    }
    else if (dataItem.productId === ProductType.Bbg) {
      if (dataItem.isRenewal == true && dataItem.policySubStatusId != PolicySubStatus.RenewalProceed && dataItem.policySubStatusId != PolicySubStatus.RenewalReferred && dataItem.policySubStatusId != PolicySubStatus.Decline) {
        this.editQuote(dataItem);
      }
      else if (dataItem.policyState == InitPolicyStatus.Quote || dataItem.policySubStatusId == PolicySubStatus.RenewalProceed || dataItem.policySubStatusId == PolicySubStatus.RenewalReferred || dataItem.policySubStatusId == PolicySubStatus.MtaPending || dataItem.policySubStatusId == PolicySubStatus.MTAReferred || dataItem.policySubStatusId == PolicySubStatus.Decline) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product-tm/bbg-quote-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
      else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/product-tm/bbg-policy-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
    }
    else if (dataItem.productId === ProductType.Poi) {
      if (dataItem.isRenewal == true && dataItem.policySubStatusId != PolicySubStatus.RenewalProceed && dataItem.policySubStatusId != PolicySubStatus.RenewalReferred && dataItem.policySubStatusId != PolicySubStatus.Decline) {
        this.editQuote(dataItem);
      }
      else if (dataItem.policyState == InitPolicyStatus.Quote || dataItem.policySubStatusId == PolicySubStatus.RenewalProceed || dataItem.policySubStatusId == PolicySubStatus.RenewalReferred || dataItem.policySubStatusId == PolicySubStatus.MtaPending || dataItem.policySubStatusId == PolicySubStatus.MTAReferred || dataItem.policySubStatusId == PolicySubStatus.Decline) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/property-owner/poi-quote-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
      else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(["/property-owner/poi-policy-dashboard"], {
            queryParams: {
              data: this.insuranceService.encryptData(encryptedqueryparams),
            },
            relativeTo: this.activatedRoute.root,
          })
        );
        window.open(url, '_blank');
      }
    }
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree(["/product/commquote-dashboard"], {
    //     queryParams: {
    //       data: this.insuranceService.encryptData(encryptedqueryparams),
    //     },
    //     relativeTo: this.activatedRoute.root,
    //   })
    // );
    // window.open(url, '_blank');
    // return;
    //  if (!dataItem?.premium  && dataItem.policySubStatusId!=8 && !this.isSystemAgent) {
    //   this.router.navigate(['product/process-renewal'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });

    // }
    // else if (dataItem.isRenewal == true && dataItem.policySubStatusId!=8 && dataItem.policySubStatusId!=10) {
    //   this.editQuote(dataItem);
    // }

  }
  editQuote(item: any) {
    let encryptedqueryparams: {
      policyId: number;
      isRenewed: any;
    };
    encryptedqueryparams = {
      policyId: item.policyId,
      isRenewed: true,
    }
    if (item.productId == ProductType.ELPL) {
      const url = this.router.serializeUrl(
      this.router.createUrlTree(['product/new-policy'], {
       queryParams: {
         data: this.insuranceService.encryptData(encryptedqueryparams)
       },
       relativeTo: this.activatedRoute.root,
      }));
      window.open(url, '_blank');
    }
    else if (item.productId == ProductType.Lei) {
      const url = this.router.serializeUrl(
      this.router.createUrlTree(['product/public-house-restaurant'], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams)
        },relativeTo: this.activatedRoute.root,
      }));
      window.open(url, '_blank');
    }
    else if (item.productId == ProductType.Fcc) {
      const url = this.router.serializeUrl(
      this.router.createUrlTree(['product/commerical-combined'], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams)
        },relativeTo: this.activatedRoute.root,
      }));
      window.open(url, '_blank');
    }
    else if (item.productId == ProductType.Off) {
      const url = this.router.serializeUrl(
      this.router.createUrlTree(['product/office'], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams)
        },relativeTo: this.activatedRoute.root,
      }));
      window.open(url, '_blank');
    }
    else if (item.productId == ProductType.Shop) {
      const url = this.router.serializeUrl(
       this.router.createUrlTree(['product/shop-retail'], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams)
        },relativeTo: this.activatedRoute.root,
      }));
      window.open(url, '_blank');
    }
    else if (item.productId == ProductType.Car) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['product/contractors-risks'], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams)
        },relativeTo: this.activatedRoute.root,
      }));
      window.open(url, '_blank');
    }
    else if (item.productId == ProductType.Sb) {
      const url = this.router.serializeUrl(
       this.router.createUrlTree(['product/self-build'], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams)
        },relativeTo: this.activatedRoute.root,
      }));
      window.open(url, '_blank');
    }
    else if (item.productId == ProductType.Rei) {
      const url = this.router.serializeUrl(
      this.router.createUrlTree(['product/real-estate'], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams)
        },relativeTo: this.activatedRoute.root,
      }));
      window.open(url, '_blank');
    }
    else if (item.productId == ProductType.Pi) {
      const url = this.router.serializeUrl(
      this.router.createUrlTree(['product-tm/professional-indemnity'], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams)
        },relativeTo: this.activatedRoute.root,
      }));
      window.open(url, '_blank');
    }
    else if (item.productId == ProductType.Pa) {
      const url = this.router.serializeUrl(
      this.router.createUrlTree(['product-tm/add-personal-accident'], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams)
        },relativeTo: this.activatedRoute.root,
      }));
      window.open(url, '_blank');
    }
    else if (item.productId == ProductType.Bbg) {
      const url = this.router.serializeUrl(
      this.router.createUrlTree(['product-tm/bb-new-policy'], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams)
        },relativeTo: this.activatedRoute.root,
      }));
      window.open(url, '_blank');
    }
    else if (item.productId == ProductType.Poi) {
      const url = this.router.serializeUrl(
      this.router.createUrlTree(['property-owner/main'], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams)
        },relativeTo: this.activatedRoute.root,
      }));
      window.open(url, '_blank');
    }
  }

  filterData(isfromstatechange: Boolean = false) {
    if (this.searchText == null) {
      return this.gridDataService.defaultLoad(
        "",
        this.searchText,
        this.policyApiBasePath,
        null,
        ""
      );
    }

    if (!isfromstatechange) {
      this.gridDataService.state.skip = 0;
      this.gridDataService.state.take = 10;
    }
    if (this.searchText.length > 2) {
      this.gridDataService.read(
        this.searchText,

        ""
      );
    } else if (this.searchText.length === 0) {
      this.gridDataService.state.skip = 0;
      this.gridDataService.state.take = 10;
      this.gridDataService.read(
        "",
        this.policyApiBasePath,

        ""
      );
    }
    this.index = this.index + 1;
    this.gridData = this.gridDataService;
  }

}
